<template src="./account.html"></template>
<style scoped src="./account.css"></style>

<script>
import { mapGetters, mapActions } from 'vuex'
import accountConstants from '@/constants/AccountConstants.vue'
import Loading from 'vue-loading-overlay'
import AccountService from '@/services/AccountService'
import { required, email } from 'vuelidate/lib/validators'
import AppConstants from '@/constants/AppConstants.vue'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import Utils from '@/utils/utils.vue'
import transactionConstants from '@/constants/TransactionConstants.vue'
import TransactionService from '@/services/TransactionService.vue'
import modal from '@/components/modal/notification/modal'
import promoService from '@/services/PromotionService'

function transaction_status_mapping(info) {   
  for (let x = 0; x < info.length; ++x) {
    info[x].TransactionStatusDescription = transactionConstants.transactionStatus.find(s => s.Value === info[x].TransactionStatus).Text
    if (info[x].DeviceOriginId === undefined || info[x].DeviceOriginId === null || info[x].DeviceOriginId === '') {
        info[x].DeviceOriginIdDescription = 'Unknown'
    } else {
    info[x].DeviceOriginIdDescription = accountConstants.deviceOrigin.find(s=>s.Value === info[x].DeviceOriginId).Text
    }
  }
}

function gateWayIdMapping(info) {
    for (let x = 0; x < info.length; ++x) {
        info[x].RemittanceGatewayIdDescription = accountConstants.remittanceGatewayId.find(s=>s.Value === info[x].RemittanceGatewayId).Text
    }
}
export default {
    data() {
        return {            
            useremail: '',
            user: null,
            recipientTableData: [],
            countryList : accountConstants.countryList,
            distinctcountries: [],
            chosenCountry: '',
            showAccountDetails: false,
            firstTransactionDate: '',
            pending: 0,
            processing: 0,
            completed: 0,
            amt: 0,
            provincesList: accountConstants.provincesList,
            accountStatus: accountConstants.accountStatus,
            accountSubStatus: accountConstants.accountSubStatus,
            riskScorceList: accountConstants.riskScorceList,
            emailStatusList: accountConstants.emailStatusListForSupport,
            mobileStatusList: accountConstants.mobileStatuslistForSupport,
            mrmsSupportStatusList:accountConstants.mrmsSupportStatusList,
            eidStatusList_forSupport : accountConstants.eidStatusList_forSupport,
            userIdType : accountConstants.userIdType,
            goldMemberShipList: accountConstants.goldMemberShipList,
            wfeFlagList: accountConstants.wfeFlagList,
            loaderVisible: false,
            currentPage: 1,
            perPage : 8,
            notes: '',
            fields: [
                {key: 'CreateDateUtc', label: 'Created Date'},
                {key: 'CreatedBy', label: 'Created by'},
                {key: 'Notes', label: 'Note' }
            ],
            showAllTransactions : false,
            errorMessage : AppConstants.formsMessages,
            isMobileStatusNonEditable : true,
            isMrmsStatusNonEditable : true,
            isEidStatusNonEditable : true,
            disableMrmsSkippedcheckbox : false,
            disableEidSkippedcheckbox: false,
            defaultSelectedValueeid : '',
            defaultSelectedValueMrms : '',
            defaultSelectedValuePhone : '',
            Utils : Utils,
            timeZoneList: [],
            transactionTableData: [],
            transactionColumns: ['OrigDateTime', 'ParentReferenceNumber', 'ReferenceNumber', 'SenderEmail', 'RecipientName', 'Amount', 'TransactionStatusDescription', 'TransactionType', 'DeviceOriginIdDescription', 'ClientIP'],
            transactionOptions: {
                headings: {
                OrigDateTime : 'Date and Time',
                ParentReferenceNumber: 'Parent Reference Number',
                ReferenceNumber : 'Reference Number',
                SenderName : 'Sender Name',
                SenderEmail: 'Sender Email',
                RecipientName: 'Recipient Name', 
                SourceCurrency: 'Source Currency', 
                Amount: 'Amount', 
                TargetCurrency: 'Target Currency', 
                TargetAmount: 'Target Amount', 
                TransactionStatusDescription: 'Transaction Status', 
                TransactionType: 'Transaction Type',
                DeviceOriginIdDescription: 'Device ID',
                ClientIP: 'ClientIP'
                },
                uniqueKey : 'ReferenceNumber',
                responsive: true,
                filterable: false,
                maintainAspectRatio: false,
            },
            recipientsColumns : ['Id','Name','MobileNumber','RemittanceGatewayIdDescription', 'Country'],
            recipientsOptions : {
                headings : {
                    Id : 'Recipient ID',
                    Name: 'Name',
                    MobileNumber : 'Mobile Number',
                    RemittanceGatewayIdDescription : 'Remittance Gateway',
                    Country: 'Country'
                },
                uniqueKey: 'Id',
                responsive:true,
                maintainAspectRatio: false,
                filterable: false,
            },
            promoColumns: ['PromoCode','Currency', 'PromoType', 'Name', 'Nomination', 'RedeemedAmount','ExpirationDate', 'RedeemedDate'],
            promoOptions: {
                headings: {
                    PromoCode : 'Promo Code',
                    PromoType : 'Promo Type',
                    Nomination: 'Promo Amount', 
                    RedeemedAmount: 'Redeemed Amount',
                    RedeemedDate: 'Redeemed Date'
                    },
                uniqueKey : 'ReferenceNumber',
                responsive: true,
                maintainAspectRatio: false,
                filterable: false,
            },
            promoCodeData: [],
            redeemedPromotionsData: [],
             alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            transactionButton: 'See All',
            promoTableFilter: "Redeemed",
            accountBalance : '',
            fieldsCH: [
                {key: 'CompliancedDate', label: 'Complianced Date' },
                {key: 'CompliancedObject', label: 'Complianced Object'},
                {key: 'ComplianceProcess', label: 'Compliance Process'},
                {key: 'ComplianceResult', label: 'Compliance Result'},
                {key: 'CreatedBy', label: 'Created By' }
            ],
            currentPageCH: 1,
            perPageCH: 8,
            complianceFilter: ''
        }
    },
    directives : {
        mask
    },
    methods : {
        ...mapActions (['fetchAccountDetails']),
        async onShowAccountDetails() {   
            try {             
                this.$v.useremail.$touch()
                if (this.$v.useremail.$invalid) {                    
                    return  
                }       
                       
                this.loaderVisible = true
                await this.fetchAccountDetails(this.useremail)
                this.user = await this.getAccountDetails
                this.showAccountDetails = true 
                
                this.user.RiskScore = this.user.RiskScore  && this.user.RiskScore !== ''?  accountConstants.riskScorceList.find(s => s.Value === this.user.RiskScore).Text : null
                this.user.UserIdType = this.user.UserIdType && this.user.UserIdType !== '' ? accountConstants.userIdType.find(s => s.Value === this.user.UserIdType).Text : null
                this.user.DateOfBirth =this.user.DateOfBirth && this.user.DateOfBirth !== '' ? moment(moment(this.user.DateOfBirth, 'YYYY/MM/DD')._d).format('MM/DD/YYYY') : null
                this.user.AccountStatus = accountConstants.accountStatus.find(s => s.Value === this.user.AccountStatus).Text
                this.user.EmailStatus = accountConstants.emailStatusList.find(s => s.Value === this.user.EmailStatus).Text
                this.user.wfe = accountConstants.wfeFlagList.find(s => s.value == this.user.wfe).name
                this.user.goldMemberShip = accountConstants.goldMemberShipList.find(s => s.value == this.user.goldMemberShip).name
                this.user.isAccountWhiteListed = this.user.isAccountWhiteListed === "true" ? true : false
                if  (this.user.SignupDate === undefined){
                    this.user.SignupDate = ""
                }
                else {
                    this.user.SignupDate = moment(moment(this.user.SignupDate, 'MM/DD/YYYY HH:mm:ss A')._d).format('MM/DD/YYYY')
                }
                if (this.user.AccountSubStatus !== null) {
                    this.user.AccountSubStatus = accountConstants.accountSubStatus.find(s => s.Value === this.user.AccountSubStatus).Text
                } 
                this.user.skipEqufax = this.user.EIDSkipped === "true" ? "Yes" : "No"
                this.user.SkipEquifaxVerification = false
                this.user.NewMRMSSkipped = false
                if (this.user.EIDRunDate) {
                    this.user.EIDRunDate = Utils.methods.UTCtoLocal(moment(moment(this.user.EIDRunDate, 'MM/DD/YYYY hh:mm:ss A')._d).format('MM-DD-YYYY HH:mm:ss')) // UTC to Local
                }
                this.user.Notes.forEach(note => {
                    note.CreateDateUtc = Utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
                })
                this.user.ComplianceHistory.forEach(ch => {
                    ch.CompliancedDate = Utils.methods.UTCtoLocal(moment(ch.CompliancedDate).format('MM/DD/YYYY HH:mm:ss'))
                })
                this.completed = 0  //set to Initital Count 
                this.processing = 0
                this.pending = 0
                this.amt = 0
                this.firstTransactionDate = ''
                let TransactionTableData = (await TransactionService.methods.GetTransactionSummary({"CustomerEmail": this.useremail})).csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                if (TransactionTableData) {
                    TransactionTableData = Array.isArray(TransactionTableData) ? TransactionTableData : [TransactionTableData]
                    this.transactionTableData = TransactionTableData
                    .map(obj => {
                        let dict = {}
                        for (let key in obj) {
                            dict[key] = obj[key]._text
                        }
                        return dict
                    })
                    .map(row => {
                    return {
                        ...row,
                        DateTime: +(new Date(row.DateTime)),
                        Amount: (+row.Amount),
                        OrigDateTime: row.DateTime,
                        OrigAmount: row.SourceCurrency + ' ' + parseFloat(+row.Amount).toFixed(2),
                        TargetAmount: parseFloat(+row.TargetAmount).toFixed(2),
                        TransactionType: row.TransactionType === 'Adjustment' ? ((row.IsCreditAdjustment).toUpperCase() === 'TRUE' ? 'Credit Adjustment' : 'Debit Adjustment') : row.TransactionType
                    }
                    })
                    transaction_status_mapping(this.transactionTableData)
                    this.transactionCalculations()
                } else {
                    this.transactionTableData = []
                }
                let RecipientTableData = (await AccountService.methods.getRecipientDetails({'CustomerEmail':this.useremail, 'Country': ''})).csGetRecipientsResponse.RecipientItems.RecipientItem
                if (RecipientTableData) {
                    RecipientTableData = Array.isArray(RecipientTableData) ? RecipientTableData : [RecipientTableData]
                    this.recipientTableData = RecipientTableData
                    .map(obj => {
                        let dict = {}
                        for (let key in obj) {
                            dict[key] = obj[key]._text
                        }
                        return dict
                    })
                    .map(row => {
                    return {
                        ...row,
                        Name: row.FirstName + ' ' + (row.LastName ? row.LastName : '')
                    }
                    })
                    gateWayIdMapping(this.recipientTableData)
                    let allcountries = []
                    for (let i = 0; i < this.recipientTableData.length; i++) {
                        allcountries.push(this.recipientTableData[i].Country)
                    }
                    this.distinctcountries = [...new Set(allcountries)]
                    this.distinctcountries.sort()
                } else {
                    this.recipientTableData = []
                    this.distinctcountries = []
                }
                //promo code usage summary table
                let promoResponse = await promoService.methods.GetPromoCodeUsageSummary({
                    IssuedTo : this.useremail
                })
                let remdeemedPromotions = []
                this.promoCodeData = promoResponse.csGetPromoCodesUsageSummaryResponse.PromoCodeList.PromoCodesUsageSummaryListItem
                if(this.promoCodeData ) {
                    this.promoCodeData = Array.isArray(this.promoCodeData) ? this.promoCodeData : [this.promoCodeData]
                    this.promoCodeData.map(promoItem =>{
                        Object.keys(promoItem).map(key => promoItem[key] = promoItem[key]._text ? promoItem[key]._text : null)
                        promoItem.PromoCode = promoItem.Code+ '-' + promoItem.Issue
                        promoItem.PromoType = promoItem.PromoCodeType === 'True' ? 'Individual' : 'Shared'
                        if(promoItem.RedeemedDate) {
                            remdeemedPromotions.push(promoItem)
                        }
                    })
                } else {
                    this.promoCodeData = []
                }
                this.redeemedPromotionsData = remdeemedPromotions
                await AccountService.methods.GetAccountBalance(this.useremail)
                .then(res => {
                    this.accountBalance = res.csAccountBalanceResponse.Balance._text? res.csAccountBalanceResponse.Balance._text : ''
                }).catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
                this.loaderVisible = false
                // adding tooltip for fields
                setTimeout(function () {
                    let inputFields = document.querySelectorAll('input,textarea')
                    let selectFields = document.querySelectorAll('select')
                    if(inputFields)
                        inputFields.forEach(element => element.setAttribute('title', element.value))
                    if(selectFields) 
                        selectFields.forEach(element => element.setAttribute('title', element.selectedOptions[0].innerText))
                }, 3000)
            }           
            catch (error) {    
                this.loaderVisible = false
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)     
            }
        },
        async handleAddNotesEvent(){
            if(this.notes.trim().length > 0) {
                this.loaderVisible = true
                await AccountService.methods.UpdateUserProfileNotes(this.notes,this.user.EmailAddress)
                .then( async() => {
                    this.notes = ''  
                    await this.updateNotesTable()
                    this.loaderVisible = false
                    this.showAlert(1, 'Notes Added Successfully')
                })
                .catch( err => {
                    this.loaderVisible = false
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)                               
                })
            } else {
                this.showAlert(null, 'Please Enter Notes')
            }
        },
        async updateNotesTable() {
            await this.fetchAccountDetails(this.useremail)
            let userDetails = await this.getAccountDetails
            userDetails.Notes.forEach(note => {
                note.CreateDateUtc = Utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
            })
            this.user.Notes = userDetails.Notes
        },
        getEmailStatus(e) {
            var modal = document.getElementById("emailModal");
            var span = document.getElementsByClassName("close")[0];
            this.toggleStatusModal(e, span, modal)
        },
        getMobStatus(e) {
            var modal = document.getElementById("mobModal");
            var span = document.getElementsByClassName("close")[1];
            this.toggleStatusModal(e, span, modal)
        },
        getMrmsStatus(e) {
            var modal = document.getElementById("mrmsModal");
            var span = document.getElementsByClassName("close")[2];
            this.toggleStatusModal(e, span, modal)
        },
        getEidStatus(e) {
            var modal = document.getElementById("eidModal");
            var span = document.getElementsByClassName("close")[3];
            this.toggleStatusModal(e, span, modal)
        },
        showComplianceHistory(e) {
            var modal = document.getElementById("complianceHistoryModal");
            var span = document.getElementsByClassName("close")[4];
            this.toggleStatusModal(e, span, modal)
        },
        toggleStatusModal(e, close, modal) {
            if (e.target.type === 'button') {
                modal.style.display = 'block'
            }
            close.onclick = function() {
                modal.style.display = "none";
            }
            window.onclick = function(event) {
            if (event.target == modal) {
                modal.style.display = "none";
                }
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        getTransactionsData() {
            this.transactionButton = this.showAllTransactions ? 'See All' : 'Recent'
            this.showAllTransactions = !this.showAllTransactions;
            return this.showAllTransactions;
        },
        transactionCalculations() {
            let data = this.transactionTableData
            for (let x = 0; x < data.length; x++) {
                if (data[x].TransactionStatus === "1") {
                    this.completed++
                    this.amt = this.amt + data[x].Amount
                }
                else if (data[x].TransactionStatus === "56" || data[x].TransactionStatus === "39" || data[x].TransactionStatus === "36" || data[x].TransactionStatus === "58") {
                    this.processing++
                }
                else if (data[x].TransactionStatus === "27" || data[x].TransactionStatus === "35") {
                    this.pending++
                }
            }
            for (let x = data.length - 1; x >= 0; x--) {
                if (data[x].TransactionStatus === "1") {
                    // this.firstTransactionDate = data[x].OrigDateTime
                    this.firstTransactionDate = moment(moment(data[x].OrigDateTime, 'MM/DD/YYYY HH:mm:ss')._d).format('MM/DD/YYYY')
                    break;
                }
            }
        },
        goBack() {
            this.$router.push({
                name : this.$route.params.fromName || 'Dashboard',
                params : this.$route.params
            })
        }
    },
    computed: {
        ...mapGetters (['getAccountDetails']),
        rows() {
            return this.user.Notes.length
        },
        rowsCH() {
            return this.complianceTableData.length
        },
        transactiondatafiltered() { 
            let data = this.transactionTableData
            var da = new Date()
            da.setDate(da.getDate() - 10);
            da = moment(moment(da)._d).format('MM-DD-YYYY 00:00:00')
            if (this.showAllTransactions) {
                data = this.transactionTableData
            }
            else {
                data = data.filter(item => item.DateTime >= new Date(da))
            }
            return data
        },
        recipientdatafiltered() {
            let data = this.recipientTableData
            if (this.chosenCountry !== "") {
                data = data.filter(item => item.Country === this.chosenCountry)
            }
            return data
        },
        issuedPromo() { return this.promoCodeData.length},
        redeemedPromo() { return this.redeemedPromotionsData.length},
        totalAmount() {
            return (this.promoCodeData.reduce((acc, item)=> acc = parseFloat(item.TransactionAmount) + acc, 0)).toFixed(2)
        },
        recentRedeemptionDate() {
            let latestDate = this.redeemedPromotionsData.reduce((acc, item) => acc = acc > new Date(item.RedeemedDate) ? acc : new Date(item.RedeemedDate), null)
            return latestDate ? moment(latestDate).format('MM/DD/YYYY') : null
        },
        promoTableData () {
            if(this.promoTableFilter === 'Not Redeemed') 
                return this.promoCodeData.filter(promo => !promo.RedeemedDate)
            else if(this.promoTableFilter === 'Expired') {
                return this.promoCodeData.filter(promo => {
                    if(promo.ExpirationDate) {
                        return new Date() > new Date(promo.ExpirationDate) 
                    }
                })
            }else{
                return this.redeemedPromotionsData
            }
        },
        complianceTableData() {
            return this.complianceFilter === 'byTransaction' ? this.user.ComplianceHistory.filter(complianceData => complianceData.CompliancedObject === 'Transaction')
                : (this.complianceFilter === 'byProfile' ? this.user.ComplianceHistory.filter(complianceData => complianceData.CompliancedObject === 'Profile')
                    : this.user.ComplianceHistory)
        },
        complianceTransactionFailuresCount() {
            return (this.user.ComplianceHistory.filter(compItem => compItem.CompliancedObject === 'Transaction'))
                .reduce((acc, profileItem) => {
                    return ['REVIEW', 'FAILED'].includes((profileItem.ComplianceResult).toUpperCase()) ? acc += 1 : acc
                },0)
        },
        complianceProfileFailuresCount(){
            return (this.user.ComplianceHistory.filter(compItem => compItem.CompliancedObject === 'Profile'))
                .reduce((acc, profileItem) => {
                    return ['REVIEW', 'FAILED'].includes((profileItem.ComplianceResult).toUpperCase()) ? acc += 1 : acc
                },0)
        }
    },
    validations : {
        useremail : {
            required,
            email
        }              
    },
    components : {
        Loading, modal
    },
    created () {
        this.timeZoneList = Array.from(new Set(this.provincesList.map(item => item.TimeZone)))
        if (this.$route.params.email) {
            this.useremail = this.$route.params.email
            this.onShowAccountDetails()
        }
    }
}
</script>